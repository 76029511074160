body.c2c-satellite.simple-solutions {
  .ui.segment.class-card-satcore {
    &.list-card {
      border: 1px solid black !important;
    }
    .card-body {
      .card-info {
        .class-card-header {
          .classroom-title-wrapper {
            .classroom-title {
              font-size: 18px !important;
            }
          }
        }
      }
    }
  }
}
