body.c2c-satellite.simple-solutions .ui.fluid.container.grade-book-view {
  .ui.segment.vertical.gradebook-card {
    .image {
      border-radius: var(--student-border-radius) 0 0 var(--student-border-radius);
    }
    .gradebook-card-content {
      .ui.basic.primary.button {
        box-shadow: none !important;
      }
      .blue {font-family: inherit;}
      .assignment-status-control {
        .assignment-status-list {
          > li:after {
            margin-left: 10.5px;
            top: -23%; // fixes the line between option bullets due to font differences.
          }
          > li:before {
            font-size: 40px;
          }
        }
      }
    }
  }
  .gradebook-container {
    .gradebook-breadcrumbs-wrapper {
      margin-top: 1px;
    }
    .btn-aggregate-gradebook {
      width: 300px;
      border-radius: 0;
      color:white;
      font-weight: 600;
      background-color: var(--theme-primary-button-bg-color);
    }
    .ui.segment.vertical.gradebook-card {
      border-radius: var(--student-border-radius);
      box-shadow: none;
      border-color: var(--card-border-color);
    }
    .btns-summary-and-details-wrapper {
      .btn-summary, .btn-details {
        &.ui.tiny.button {
          &:not(.primary) {
            border: 1px solid #dedfdf;
            background: #f3f3f3 !important;
            color: #626262 !important;
          }
        }
      }
      .btn-summary {
        border-radius: var(--theme-button-border-radius) 0 0 var(--theme-button-border-radius) !important;
      }
      .btn-details {
        border-radius: 0 var(--theme-button-border-radius) var(--theme-button-border-radius) 0 !important;
      }
    }
  }
  .grade-book-layout {
    .gradebook-header-container {
      .gradebook-header-left {
        h3.header {
          color: var(--theme-primary-button-color);
        }
      }
      .btn-export {
        padding: 0px;
      }
    }
    .gradebook-data-content {
      border: solid 1px var(--card-border-color);
      background-color: var(--card-content-background-color);
      border-radius: var(--student-border-radius);
      padding: 17px 28px;
      justify-content: space-between;
      .fg-col {
        .card-value.grade-low,
        .card-value.grade-med,
        .card-value.grade-high {
          color: inherit;
        }
      }
      .fg-col.progress .fg-row {
        align-items: baseline;
        .progress-bar {
          border-radius: var(--student-border-radius);
          background-color: #767676;
          height: 10px;
          width: 120px;
          .grade-high,
          .grade-med,
          .grade-low {
            height: inherit;
            background-color: var(--theme-secondary-font-color);
          }
        }
      }
    }
    tbody tr td.cell-submitted .ui.primary.button {
      font-size: 10px;
      font-weight: normal !important;
      padding: 0px;
      background-color: #cc2f44;
      border-radius: var(--student-border-radius) !important;
    }
  }
}
