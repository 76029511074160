body.c2c-satellite.simple-solutions {
  .ui.segment.item.student-assignment-card {
    border: 1px solid #cfcfcf !important;
    .flex-grid-spaced .fg-column.dash-sub .card-label {
      font-size: 13px;
      font-weight: normal;
      color: #777777;
    }
    .content {
      padding-bottom: 0;
    }
    .card-nav-buttons {
      .ui.button {
        min-width: 80px;
        &:first-child:nth-last-child(n + 2) {
          padding: 0.6em 1.7em;
          & ~ .ui.ui.button {
            padding: 0.433em 0.9em;
          }
        }
      }
    }
  }
}
