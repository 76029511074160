.button-basic(@color, @hover) {
  &.buttons .button, &.button {
    background: transparent !important;
    border: solid 2px @color;
    color: @color !important;
    &:hover, &:focus, &.active {
      color: @hover !important;
    }
  }
}
.button-primary-basic() {
  .button-basic(var(--theme-primary-button-bg-color), var(--theme-primary-button-hover-color))
}
body.c2c-satellite.simple-solutions {
  .ui.button, .ui.basic.button, .ui.primary.button {
    padding: 0.6em 1.7em;
    font-family: var(--theme-font-family);
  }
  .ui.primary.button {
    font-weight: bold !important;
    padding: 0.6em 1.7em;
    border-radius: var(--theme-button-border-radius) !important;
    background-color: var(--theme-primary-button-bg-color);
    box-shadow: none !important;
    &:focus {
      background: none var(--theme-primary-button-bg-color);
      color: var(--theme-primary-button-color);
      text-shadow: none !important;
    }
    &:hover {
      background: none var(--theme-primary-button-hover-color);
      color: var(--theme-primary-button-color) !important;
      border: 0;
      text-shadow: none !important;
      box-shadow: none !important;
    }
  }
  .ui.disabled.button {
      border-radius: var(--theme-button-border-radius);
      background-color: var(--theme-primary-button-bg-disabled-color);
      color: #767676;
  }
  .ui.mini.button {
    padding: 0.6em 1.7em;
    border-radius: var(--theme-button-border-radius);
    border: solid 2px var(--theme-primary-button-bg-disabled-color) !important;
    font-weight: bold;
  }
  .ui.basic.button:not(.white) {
    border: solid 2px var(--theme-primary-button-bg-color);
    border-radius: var(--theme-button-border-radius);
    box-shadow: none !important;
    font-weight: bold;
    &:hover {
      border: solid 2px var(--theme-primary-button-hover-color);
      color: var(--theme-primary-button-hover-color) !important;
      box-shadow: none !important;
    }
  }
  .ui.tiny.button {
    line-height: 1.15;
    // color: white;
    padding: 0.6em 1.7em;
    border-radius: var(--theme-button-border-radius);
  }
}
