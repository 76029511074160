body.c2c-satellite.simple-solutions {
  .ui.button.login, .ui.button.sign-up {
    width: 87px !important;
    height: 41px !important;
    font-size: 16px;
    padding: 0 !important;
    border-radius: var(--theme-secondary-button-border-radius) !important;
  }
  .ui.grid.login-body {
    background-size: contain;
    background-image: url('../img/simple-solutions-bg.png');
    .login-center-logo {
      display: block;
      width: 201px;
      height: 151px;
      margin: 0 auto;
      background-image: url('../img/si-star-center-logo.png');
      background-repeat: no-repeat;
    }
    .header .header-text {
      font-weight: bold;
    }
    .element-body-login {
      box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px #fbfbfc;
      .field {
        > label {
          font-weight: normal;
          color: var(--theme-font-color);
        }
        input {
          line-height: 1.45;
        }
        .forgot-link {
          color: var(--theme-font-color);
          font-size: var(--theme-font-size);
        }

        .ui.segment.button-list {
          display: flex;
          justify-content: center !important;
          width: fit-content !important;
          div {
            white-space: nowrap;
            &:not(:last-child) {
              margin-right: 20px;
            }
          }
          margin: auto;
          border: 0;
          .ui.button {
            line-height: normal;
          }
        }
      
        .ui.segment.single-button {
          font-family: var(--theme-font-family);
          width: 100%;
          margin: auto;
          border: 0;
          box-shadow: none;
          background-color: var(--theme-element-color-login);
          .ui.button {
            font-family: var(--theme-font-family);
            font-size: 16px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            text-align: center;
            color: #fbfbfc;
          }
        }
      
        .ui.segment.sso-button-list {
          width: 246px;
          margin: auto;
          padding: 20px 0 20px 0;
          border: 0;
          border-top: 1px solid var(--theme-dark-border-color);
          box-shadow: none;
          display: flex;
          flex-direction: column;
          background-color: var(--theme-element-color-login);
          .ui.button {
            padding: 2px 0;
            // img {
            //   margin: unset;
            // }
            [class*='label'] {
              color: var(--theme-secondary-button-color);
              white-space: nowrap;
              margin: auto !important;
            }
          }

          // .ui.button.clever {
          //   width: 246px;
          //   height: 41px;
          //   padding: 2px 0;
          //   display: flex;
          //   margin: auto !important;
          //   margin-bottom: 5px;
          //   .clever-logo {
          //     margin: auto !important;
          //   }
          //   .clever-label {
          //     margin: auto !important
          //   }
          // }
          
        }
      }
    }
    .login-body-inner {
      .login-bottom-logo-wrapper {
        height: 60px;
        width: 133px;
        img {
          height: 60px;
          width: 133px;
        }
      }
    }
  }
  .ui.header > .ui.image.logo {
    display: none;
  }
  .form .ui.attached.block.header {
    border: none;
  }
  .message-wrapper {
    margin-bottom: 21px;
    .signin-message {
      display: flex;
      justify-content: center;
      img {
        margin-right: 5px;
      }
      a {
        font-size: 16px;
        line-height: 26px;
        font-weight: 500;
        color: var(--theme-secondary-font-color);
      }
    }
  }
}
