body.c2c-satellite.simple-solutions {
  .ui.container.dashboard-container {
    .widget-wrapper {
      width: fit-content;
      .left-col {
        display: none; 
      }
      .dashboard-widget.green .w-content .ui.items.scroller .dash-card.ui.items {
        border-bottom: none;
        .student-assignment-card {
          min-width: 0px !important;
          max-width: 1023px !important;
          min-height: 129px !important;
          max-height: 100% !important;
          .image img {
            border-radius: var(--student-border-radius) 0px 0px var(--student-border-radius);
          }
        }
      } 
      .right-col {
        width: 100%;
      }
      .dashboard-widget {
        .w-content, .w-title {
          background-color: #fff;
          font-family: var(--theme-font-family) !important;
          font-size: 22px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          color: rgba(0, 0, 0, 0.87);
        }
        .w-content {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          min-height: 204px;
        }
        .w-title {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          border-top: 13px solid;
        }
        .actions .button {
          font-size: 12px !important;
          padding-top: 0.6em;
        }
        // TODO unused
        // &.red {
        //   .w-content {
        //     .slick-arrow {
        //       margin-top: 15px;
        //      &:before {color: #707070;}
        //       &.slick-next {
        //         border-radius: 0px var(--student-border-radius) var(--student-border-radius) 0px;
        //       }
        //       &.slick-prev{
        //         border-radius: var(--student-border-radius) 0px 0px var(--student-border-radius);
        //       }
        //     }
        //     .course-card-image {
        //       border-radius: 0px;
        //     }
        //     .actions .button {
        //       margin: 10px auto 14px;
        //     }
        //   }
        //   .w-title {
        //     border-top-color: #b3271f;
        //   }
        // }
        &.green {
          .w-title {
            border-top-color: #ec1537;
          }
          .no-assignments-message {
            color: var(--theme-font-color);
          }
        }
        &.progress {
          visibility: hidden;
        }
      }
    }
  }
  .student-client .ui.container.dashboard-container .headerContainer .headerWrapper {
    .welcome-header {
      font-size: 28px;
      margin-bottom: 20px;
    }
    .welcome-date {
      color: var(--theme-font-color);
      font-weight: bold;
      font-size: 18px;
    }
  }
}
