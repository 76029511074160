body.c2c-satellite.simple-solutions .class-roster-view {
  
  .classRosterTable {
    .table-body {
      .table-row {
        .table-cell.table-cell-small {
          width: 14% !important;
          .ui.button {
            font-size: 11px;
          }
        }
        .table-cell.table-cell-medium {
          width: 17% !important;
          .ui.button {
            font-size: 11px;
          }
        }
      }
    }
    .table-header-row {
      .table-header-cell.table-header-cell-small {
        width: 14% !important;
      }
      .table-header-cell.table-header-cell-medium {
        width: 17% !important;
      }
    }
  }
  .ui.container.roster-tab-container {
    .tab-button {
      border: solid 1px #dedfdf;
      background-color: var(--theme-background-color);
    }
    .active {
      background-color: var(--theme-dark-border-color);
    }
  }
}
