/* any satellite-specific report style overrides can go into this file */
body.c2c-satellite.simple-solutions {
  .report-view {
    /* placeholder */
  }

  .report-type-selector-view .report-selector-cards {
    .report-selector-card .card-bottom {
      .report-selector-list {
        .ui.list .item {
          .content > .description > span > .ui.image {
            width: 44px;
          }
          .selector-classroom-name-wrapper {
            min-width: fit-content;
          }
        }
      }
    }
  }

  .report-context-switcher {
    .context-switcher-button {
      .ui.button {
        font-size: 12px !important;
      }
      .ui.button:not(.primary) {
        background-color: var(--theme-secondary-button-bg-color);
        color: var(--theme-secondary-button-color);
      }
    }
  }

  .report-color-key-card {
    &:not(.individual) {
      min-width: 454px !important;
      max-width: 454px !important;
    }
  }
}
