body.c2c-satellite.simple-solutions .page .ui.fullscreen.modal.full-screen-modal-page.profile-page {
  .profile-txt {
    /* placeholder */
  }
  .profile-fields .profile-field-txt {
    /* placeholder */
  }
  background-image: url('../img/simple-solutions-bg.png');
  .ui.mini.button.avatar-button {
    /* placeholder */
  }
  .ui.basic.primary.button.profile-change-password-btn {
    /* placeholder */
  }
}
