body.c2c-satellite.simple-solutions .verification-page {
  height: 100vh;
  .verification-header .header-text {
    font-weight: bold;
  }
  .panel-body {
    margin: 20px 0;
  }
  .element-body-verification {
    border: solid 1px #666666;
  }
}
