body.c2c-satellite.simple-solutions {
  .ui.fluid.container.student-assignment-view-container {
    .sa-header-row-img {
      margin-top: 30px;
      background: none;
      color: inherit;
      .sa-header-label {
        color: var(--theme-font-color-directly-on-background) !important;
        font-family: var(--theme-font-family) !important;
      }
    }
    .ui.menu {
      display: flex;
      background-color: var(--theme-banner-color);
      border: 1px solid #cfcfcf !important;
      height: 38px;
      font-size: 15px;
      font-weight: 600;
      border: none;
      border-radius: 30px !important;
      box-shadow: none;
      .item {
        cursor: pointer;
        min-width: 150px;
        justify-content: center;
        color: var(--theme-font-color-directly-on-background) !important;
        font-weight: 600;
        border: none;
        padding-top: 20px;
        div {
          border-bottom: 5px solid transparent;
        }
        &.active {
          background: var(--theme-primary-button-bg-color);
          color: white !important;
          box-shadow: none;
          div {
            padding: 5px;
          }
        }
        &:not(:last-child) {
          border-right: 1px solid #cfcfcf !important;
        }
      }
    }
  }
  .sa-today-element-img {
    background-image: url('../img/student-today-is.svg');
  }
}
