body.c2c-satellite.simple-solutions .class-settings-view {
  .class-header {
    h2 {
      color: var(--theme-font-color);
    }
    background: inherit !important;
  }
  .ui.fluid.container.bread-crumb-wrapper.bottom {
    display: none;
  }
  .ui.container.settings-form .settings-grid .settings-save-button {
    width: 90px;
    float: left;
    margin-top: 20px;
  }
  .ui.container.settings-image {
    height: max-content;
  }
  .ui.container.settings-form {
    background: inherit;
    background-size: cover;
    .settings-grid .column h2.ui.header {
      display: none;
    }
  }
  .studentAccessCode {
    background: inherit;
  }
  .accessCodeHeader {
    // placeholder
  }
}
