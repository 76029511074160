:root {
  --access-code-input-line-height: 1.9;
  --admin-classrooms-filtered-header-table-cell-button-line-height: 0;
  --border-radius: 4px;
  --card-background-color: #767676;
  --card-border-color: #dadadb;
  --card-content-background-color: #fff;
  --card-tag1-background-color: #6435c9;
  --card-tag2-background-color: #21ba45;
  --color-key-card-admin-classrooms-iconKeys-square-padding: 2px 0 0 0;
  --demolink-launcher-box-button-height: auto;
  --modal-title-text-color: black;
  --report-default-approaching: #e3ad4b;
  --report-default-developing: #db2828;
  --report-default-meeting: #80bd44;
  --report-default-not-enough-data: #767676;
  --student-border-radius: 4px;
  --theme-background-color-login: #fff;
  --theme-background-color: #eee;
  --theme-background-header-color: #fff;
  --theme-banner-color: #fff;
  --theme-bright-green: #3cb24a;
  --theme-bright-orange: #edab2f;
  --theme-bright-red: #cd373d;
  --theme-button-border-radius: 4px;
  --theme-element-color-login: #fff;
  --theme-element-color: #fff;
  --theme-font-color: rgba(0, 0, 0, 0.8);
  --theme-font-family: 'Verdana', arial, sans-serif;
  --theme-font-size: 13px;
  --theme-header-color: rgba(0, 0, 0, 0.87);
  --theme-icon-color: invert(23%) sepia(98%) saturate(2007%) hue-rotate(186deg) brightness(100%) contrast(101%);
  --theme-light-green: #eef4e9;
  --theme-light-orange: #efe0be;
  --theme-light-red: #f0d3d4;
  --theme-link-color: #008ca8;
  --theme-primary-button-bg-color: #ec1537;
  --theme-primary-button-bg-disabled-color: #dadadb;
  --theme-primary-button-color: #fff;
  --theme-primary-button-hover-color: #df1232;
  --theme-secondary-banner-color: black;
  --theme-secondary-border-color: #808284;
  --theme-secondary-button-bg-color: #707070;
  --theme-secondary-button-border-radius: 8px;
  --theme-secondary-button-color: #fff;
  --theme-secondary-font-color: #3a9cfa;
  --theme-secondary-font-size: 11px;
  --theme-student-carousel: #dadadb;
  --theme-top-nav-color: black;
  --theme-top-nav-selected-color: #fff;
  --top-nav-notification-bg-color: #ec1537;
  --top-nav-notification-color: white;
}

// //noinspection CssUnknownTarget
// @import url("https://p.typekit.net/p.css?s=1&k=slm3pob&ht=tk&f=2028.2029.2032.2033.44248.44250.44251.44252.44254.44255&a=10088794&app=typekit&e=css");

@import "Buttons";
body.c2c-satellite.simple-solutions {
  #root {
    background-color: var(--theme-background-color) !important;
    background-image: url(../img/simple-solutions-bg.png);
  }
  font-family: 'Verdana', arial, sans-serif;
  font-size: 13px;
  font-weight: normal;

  .use-react-wrapper {
    display: none;
  }

  .teacher-present-button {
    display: none;
  }
  .ui.image.logo {
    width: 56px;
  }
  .ui.cards .ui.segment {
    padding: 5px;
    box-shadow: none;
  }
  
  .ui.container.class-course-container .ui.segment.course-card-satcore {
    padding: 5px;
  }

  .class-settings-view .ui.container.settings-image .image-wrapper .default-class-image {
    background-color: var(--card-background-color);
  }
  .bread-crumb-wrapper {
    padding: 10px 40px;
    background-color: var(--theme-background-header-color);
    display: block;
    margin: 0;
    border-top: 1px solid black;
  }
  .ui.large.breadcrumb {
    font-size: var(--theme-font-size) !important;
  }
  /* Checkboxes */
  .sc-check {
    .check-label {
      font-size: 12px;
    }
    .checkmark {
      border-color: #707070;
    }
    .checkmark:after {
      font-weight: bold;
    }
  }
  .sc-radio .radio-label {
    font-size: 12px;
    .radio-button {
      border-color: #959595;
    }
  }
  /* Icons background */
   .resource-pacing-toolbar-container .toolbar-item-trigger {
    img {
      filter: var(--theme-icon-color);
    }
    &.manual-scoring img {
      filter: inherit;
    }
  }
  .resource-pacing-modal-container .modal-section .modal-section-content {
    .resource-duration .resource-duration-input-wrapper .resource-duration-img-wrapper img,
    .resource-option.teacher-assign img,
    .resource-option.visible-to-students .resource-option-img-wrapper img {
      filter: var(--theme-icon-color); 
    }
  }

  /* Access codes */
  .accessCode {
    .accessCodeRefresh {
      margin-top: .3em;
    }
    .accessCodeLabel {
      // placeholder
    }
  }

  /* Pagination */
  .ui.menu {
    box-shadow: none;
  }
  .ui.pagination.menu {
    .active.item {
      background-color: #dadadb;
    }
    .item {
      border-left: solid 1px #e2e2e2;
    }
  }
  
  /* Add course */
  .class-header .overlay {
    display: none;
  }

  .course-tree-leaf-card-nav-buttons .ui.primary.button,
  .course-tree-leaf-card-nav-buttons .ui.basic.primary.button,
  .roster-table-container .ui.primary.button,
  .roster-table-container .ui.basic.primary.button {
    font-size: var(--theme-secondary-font-size) !important;
  }

  .roster-table-container .student-data {
    font-size: 13px !important;
    text-wrap: nowrap;
  }

  .student-client {
    overflow: hidden;
    .no-assignments-wrapper {
      width: 100%;
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .no-assignments-img {
        width: 154px;
        height: 187px;
        background-image: url(../img/graphic-zero-state.svg);
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 auto;
      }

      .no-assignments-message {
        width: auto;
        height: 28px;
        font-family: 'Verdana', arial, sans-serif;
        font-size: 28px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
        color: #0072bc;
        margin: 0 auto;
      }
    }

    .ui.segment.item.student-assignment-card {
      border-radius: var(--border-radius) !important;
      min-width: 1023px;
      max-width: 1023px !important;
      min-height: 115px;
      max-height: 120px !important;
      padding-left: 0;
      .content {
        padding-top: 9px;
        padding-bottom: 0px;
      }
      .image {
        margin-left: 5px;
        img {
          border-radius: var(--student-border-radius) 0 0 var(--student-border-radius);
          padding: 0;
          width: 155px;
          height: 105px;
        }
      }
    }
    /* end .student-client */
  }
  @media only screen and (max-width: 765px) {
    .ui.segment.item.student-assignment-card {
      min-width: 650px !important;
      max-width: 650px !important;
      min-height: 310px !important;
      max-height: auto !important;
      .image {
        height: auto;
        max-height: 175px;
        img {
          border-radius: 0 !important;
          width: auto;
          height: auto;
          max-height: 175px;
        }
      }
    }
  }

  .ui.popup {
    .popup-text-wrapper {
      .card-list-text {
        display:inline;
        margin: 8px 0 0 2px;

        &.blue {
          color: var(--theme-secondary-font-color);
        }

        &.clickable {
          cursor: pointer;
        }
      }
    }

    &.gradebook-assignment-column-popup {
      div div .button {
        width: 118px;
        height: 30px;
      }
    }

    &.gradebook-summary-table-popup {
      .centered-popup-box {
        div .button {
          width: 88px !important;
        }
      }
    }
  }

  .ckeditor {
    border-radius: var(--border-radius);
    box-shadow: none;
    border: 1px solid var(--card-border-color);
  }
  .ui.segment.list-card {
    background: var(--card-content-background-color);
    margin: 1rem auto;
    width: 100%;
    max-width: 1127px;
    border-radius: var(--border-radius);
    box-shadow: none;
    border: 1px solid var(--card-border-color);
    .course-view-button, .popup-button {
      .button-primary-basic();
    }
    .button {
      min-width: 80px !important;
    }
    .leaf-card-image-wrapper {
      background-color: #0072bc;
    }
  }

  .toggle-hide-or-show-wrapper {
    .ui.toggle.checkbox input:checked ~ label:before {
      font-size: 11.5px;
      padding: 2px 0 0 9px;
    }
    .ui.toggle.checkbox label:before {
      font-size: 11px;
      padding: 2px 0 0 23px;
    }
  }

  .ui.toggle.checkbox.cell-grades-toggler label:before {
    padding: 2.75px 0 0 17px !important;
  }
  .ui.toggle.checkbox.cell-grades-toggler input:checked ~ label:before {
    padding: 2.25px 13px 0 0 !important;
  }

  @media only screen and (min-width: 1200px) {
    .ui.container.class-course-container,
    .ui.container.class-course-list-container,
    .ui.container.class-content {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  @media only screen and (min-width: 1200px) {
    .ui.container.dashboard-container {
      width: calc(100% - 20px);
      max-width: 1127px !important;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  .ui.container.class-course-container .ui.segment.course-card-satcore {
    .course-card-image-wrapper {
      padding: 5px;
      .course-card-image {
        background-color: var(--card-background-color) !important;
        max-height: 104px;
        max-width: 157px !important;
      }
    }
  }
  .standards-list-container .name-pill {
    margin: 2.5px;
  }
  /* Buttons */
  .ui.segment.item.assignment-card .ui.primary.button {
    box-shadow: none !important;
    font-size: var(--theme-secondary-font-size) !important;
    min-width: 85px !important;
    min-height: 26px !important;
  }

  .ui.container.class-detail .ui.segment.list-card .card-nav-buttons button {
    box-shadow: none !important;
    font-size: var(--theme-secondary-font-size) !important;
    min-height: 26px !important;
    min-width: 85px !important;
    padding-bottom: 1px !important;
  }

  .ui.button.basic.primary.submit-button {
    padding: 0;
  }
  .ui.mini.button.password-display-btn {
    border-radius: var(--theme-secondary-button-border-radius);
    border: 2px solid var(--theme-secondary-border-color) !important;
    font-weight: 700;
    margin-left: -106px !important;
    width: 100px;
  }
  .class-header .ui.button.primary.add-course-button {
    font-weight: bold;
    font-size: 13px;
  }
  .ui.segment.item.student-assignment-card .ui.primary.button {
    width: auto;
    height: auto;
  }
  .student-client {
    .ui.segment.leaf-card-satcore.list-card, .ui.segment.branch-card-satcore.list-card {
      .card-nav-buttons .ui.button {
        font-size: var(--theme-secondary-font-size) !important;
        height: auto;
        width: 80px;
      }
    }
    .ui.segment.course-card-satcore.clickable.list-card .card-nav-buttons button.course-view-button {
      font-size: var(--theme-secondary-font-size) !important;
      height: auto;
      width: 80px;
    }
    .ui.segment.leaf-card-satcore.list-card .card-nav-buttons .ui.button.leaf-button,
    .ui.segment.branch-card-satcore.list-card .card-nav-buttons .ui.button.leaf-button {
      font-size: var(--theme-secondary-font-size) !important;
    }
 
    .ui.container.class-detail .ui.segment.list-card .card-nav-buttons button {
      padding-top: 0px !important;
      padding-bottom: 1px !important;
    }
  }
  /* Modal */
  .ui.modal {
    &.fullscreen {
      .f-header {
        .modal-actions {
          .ui.basic.button {
            background: white !important;
            color: black !important;
          }
        }
      }
    }
    .content {
      .assignment-banner {
        padding: 5px 0;
        .title-wrapper {
          margin-left: 16px;
        }
        .flex-subheader {
          color: #848484;
          font-size: 13px;
        }
      }
      .assignment-wrapper-modal {
        .ui.basic.button {
          color: var(--theme-primary-button-bg-color) !important;
        }
      }
    }
  }

  .individual-student-performance .grid-column-header {
    padding: 0.92857143em 0.78571429em;
    font-size: 12px;
  }
  .ui.segment.item.assignment-card {
    padding: 5px;
  }

  .admin-add-teacher-page .admin-user-add-teacher-header .ui.button {
    text-align: center;
    padding: 0;
  }
}
