body.c2c-satellite.simple-solutions .ui.fullscreen.modal {
  .modal-header {
    .modal-header-bar {
      .modal-header-buttons {
        .selected-count-wrapper {
          .ui.circular.label {
            background-color: var(--top-nav-notification-bg-color) !important;
            color: var(--top-nav-notification-color) !important;
          }
        }      
      }
    }
  }
  .ui.bottom.attached.segment.active.tab {
    height: 100vh;
  }
  .tab-pane-publisher-courses .ui.segment {
    border: solid 1px #dadadb !important;
  }
  .sc-check .check-label {
    overflow: inherit !important;
  }
}
