// Modals.less - intended for general CSS declarations for modals of a given satellite
// custom modals with large changes (such as AddCourseModal.less) are preferred to have their own CSS file
body.c2c-satellite.simple-solutions {
  .ui.modal {
     .header.modal-header, .actions {
      background: var(--theme-banner-color) !important;
    }
    .modal-banner-title {
      color: black;
    }
  }
  .AddAssignmentModal, .EditAssignmentModal, .EditBulkAssignmentModal {
    .actions, .modal-header {
      background: var(--theme-banner-color) !important;
    }
    .modal-header {
      .modal-banner-title {
        color: black;
      }
    }
  }
}
