body.c2c-satellite.simple-solutions {
  .class-detail {
    .no-courses {
      color: var(--theme-font-color-directly-on-background) !important;
    }

    .list-card {
      .branch-card-image-wrapper {
        width: 157px !important;
        .branch-card-image:not(.with-image) {
          padding: 27px 40px !important;
        }
      }
      .leaf-card-image-wrapper {
        width: 157px !important;
      }
    }
  }

  .student-client {
    .list-card {
      .leaf-card-image-wrapper {
        // placeholder
      }
      .card-nav-buttons {
        .button {
          // placeholder
        }
      }
    }
  }
}
