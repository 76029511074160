.ui.fullscreen.modal.AddCourseToClassModal {
  .modal-header-bar {
    .selected-text-label {
      padding: 0 13px 0 6px !important;
    }
    .modal-header-buttons {
      .ui.button {
        border-radius: var(--theme-secondary-button-border-radius) !important;
        font-size: var(--theme-font-size) !important;
        height: 36px !important;
        width: 101px !important;
      }
    }
  }

  .ui.tabular.menu {
    display: none;
  }

  .tab-pane-publisher-courses {
    background-color: var(--theme-background-color) !important;
    background-image: url(../img/simple-solutions-bg.png) !important;
    .content {
      .class-course-container {
        .class-course-list-container {
          padding: 40px 90px !important;
        }
      }
    }
  }
  
  .sc-check {
    .check-label {
      .checkmark {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
        border: 1px solid #707070 !important;
        &::after {
          padding: 3px 0 3px 0 !important;
        }
      }
      overflow: inherit !important;
    }
  }
  .course-card-satcore {
    &.course-card-satcore-disabled {
      // placeholder
    }
    .card-nav-buttons {
      .button {
        background-color: var(--card-content-background-color) !important;
        border: 1px solid var(--theme-primary-button-bg-color) !important;
        border-radius: var(--theme-secondary-button-border-radius) !important;
        color: var(--theme-primary-button-bg-color) !important;
        font-size: var(--theme-font-size) !important;
        height: 36px !important;
        width: 101px !important;
      }
    }
  }
}
