body.c2c-satellite.simple-solutions .content-area {
  .teacher-banner-container {
    height: 41px;
    border-top: 8px solid #9e1219;
    border-bottom: 8px solid #9e1219;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .teacher-banner-logo {
      width: 27px;
    }

    .teacher-banner-text {
      font-weight: bold;
      font-size: 16px;
    }
  }
  
  .dashboard-container {
    .welcome-header {
      .mobile-only {
        display: none !important;
      }
    }
    .welcome-date {
      font-family: var(--theme-font-family);
      font-weight: bold;
      color: var(--theme-font-color);
    }
    .ui.segment.class-card-satcore {
      padding: 5px 0;
      .card-body .subtitle .leaf-description-text {
        color: #848484;
      }
      .ui.divider {
        width: 100%;
        margin-top: 3.5px;
      }
      .card-body {
        padding: 0px !important;
        margin-top: 15px;
        height: fit-content;
        line-height: 1.8;
        width: 100%;
        .card-info {
          padding: 0 15px;
          .class-card-info {
            margin-bottom: 24px;
          }
        }
        .card-nav-buttons {
          padding-left: 15px;
        }
      }
      .class-card-image-wrapper {
        margin: 7px 0px 7px 12px;
        .class-card-image {
          &.default-image { background: var(--card-background-color); }
          width: 154px;
          border-radius: 0px;
          .ui.image.class-image {
            border-radius: 0;
          }
        }
      }
    }
  }
}