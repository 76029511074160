body.c2c-satellite.simple-solutions .assignment-view-container {
  .subHeaderWrapper {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  .filter-content-type-section {
    display: none;
  }
  .ui.table {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border: none;
  }
  .ui.segment.item.assignment-card {
    .flex-grid.top {
      margin-bottom: 3px;
    }
    .content {
      padding: 10px 20px 0px 10px;
    }
    .row .subtitle .expander {
      color: #848484;
      margin-top: 6px;
      margin-bottom: 8px;
    }
    .card-value {
      font-size: 13px;
      font-weight: normal;
    }
  }
  .filter {
    .ui.grid {
      margin-right: 0;
      margin-top: 0;
    }
    .ui.table {
      td.active {
        background-color: #3a9cfa !important;
        outline: none;
      }
    }
  }
}
