body.c2c-satellite.simple-solutions {
  .ui.inverted.menu.topNav {
    background-color: var(--theme-banner-color);
    .header.item {
      .ui.image.logo {
        width: auto !important;
      }
    }
    .item.tnav-tab {
      font-size: 14px;
      text-align: center;
      color: var(--theme-top-nav-color);
      text-transform: uppercase;
      font-weight: bold;
      &.selected {
        background-color: var(--theme-primary-button-bg-color);
        color: var(--theme-top-nav-selected-color);
      }
      &.disabled {
        &, &:hover {
          color: rgba(40, 40, 40, 0.3) !important;
        }
      }
      &.resources {
        .ui.image {
          display: none !important;
        }
      }
      &.help-nav {
        padding: 10px;
        .notification-count-wrapper {
          min-width: 150px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid var(--top-nav-notification-bg-color);
          border-radius: 15px;
          font-size: 13px;
          font-weight: bold;
          color: var(--top-nav-notification-bg-color);
          background-color: var(--top-nav-notification-color);
          text-transform: capitalize;
        }
      }
    }
  }
  .nav-separator {
    display: none;
  }
  .ui.avatar.images .image, .ui.avatar.images img, .ui.avatar.images svg, .ui.avatar.image img, .ui.avatar.image svg, .ui.avatar.image {
    width: 36px;
    height: 36px;
  }
  .main-view .ui.inverted.menu.topNav {
    .class-selector {
      .class-dropdown-selection {
        margin-left: 0px;
      }
      .item {
        padding: 0.58347222em 1.14285714em !important;
        &.switch-option {
          background-color: #dadadb !important;
          .join-class {
            padding: 0.6em 1.2em;
            width: 94px;
            min-width: fit-content;
          }
        }
        &:not(:first-child):not(:last-child) {
          border-bottom: 1px solid #d4d4d5;
        }
      }
    }
  }
  .student-client {
    .nav-separator {
      display: unset !important;
      position: fixed;
      z-index: 100;
      width: 100%;
      height: 1px;
      background-color: black;
    }
  }
}
